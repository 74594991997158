import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_vilkencykel/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import ProductsDisplay from "../../components/productDisplay.js";
import CategoriesLinks from "../../components/categoriesLinks.js";
export const query = graphql`
  query {
    allMongodbStoreProducts(sort: {fields: popularity_score, order: DESC}, filter: {brand: { eq: "haibike" }}) {
      edges {
        node {
          	brand
			full_name
			id
			local_image_name
			model
			original_prices
			popularity_score
			prices
			product_id
			similair_products
			show_on_pages
			sub_product_type
			store
			url
			engine
			last_updated_date
			derived_properties {
				motor_type
				average_range_km
				number_of_gears
				battery_capacity
				frame_type
			}
			fields {
				brand_url
				full_name
				full_name_url
				model_url
			}
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "upptäck-haibike-elcyklar"
    }}>{`Upptäck Haibike Elcyklar`}</h1>
    <p>{`Välkommen till vår dedikerade sida för Haibike elcyklar - din portal till marknadens mest avancerade och högkvalitativa elcyklar för både stad och terräng. Haibike, ett varumärke med rötterna i innovation och tysk ingenjörskonst, erbjuder en imponerande serie elcyklar som förvandlar varje cykeltur till en upplevelse utöver det vanliga. Här guidar vi dig genom de olika serierna av Haibike elcyklar och hjälper dig hitta den perfekta modellen för dina behov.`}</p>
    <h2 {...{
      "id": "haibike-elcykel-serier"
    }}>{`Haibike Elcykel Serier`}</h2>
    <h3 {...{
      "id": "haibike-trekking-4-series"
    }}>{`Haibike Trekking 4 Series`}</h3>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Kraftfull Yamaha mittmotor`}</li>
      <li parentName="ul">{`Batterikapacitet upp till 80 km`}</li>
      <li parentName="ul">{`Robust ram och dämpning`}</li>
      <li parentName="ul">{`Effektiva skivbromsar`}</li>
      <li parentName="ul">{`Intuitiv Yamaha LCD-display`}</li>
      <li parentName="ul">{`Passar både för stadsmiljö och terräng`}</li>
    </ul>
    <p>{`Haibike Trekking 4 är den perfekta hybriden för både stads- och terrängkörning. Med sin kraftfulla Yamaha motor och robusta dämpningssystem erbjuder Trekking 4 en komfortabel och pålitlig cykelupplevelse oavsett underlag.`}</p>
    <h3 {...{
      "id": "haibike-trekking-5-series"
    }}>{`Haibike Trekking 5 Series`}</h3>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Kraftfull Yamaha mittmotor`}</li>
      <li parentName="ul">{`Batterikapacitet på 720 Wh`}</li>
      <li parentName="ul">{`Integrerad belysning och bagagehylla`}</li>
      <li parentName="ul">{`Shimano komponenter för växling och bromsning`}</li>
      <li parentName="ul">{`27,5-tums däck för mångsidig användning`}</li>
    </ul>
    <p>{`För den som söker en elcykel för både daglig pendling och äventyrliga utflykter är Haibike Trekking 5 det optimala valet. Kombinationen av kraft, robusthet och extra funktioner såsom integrerad belysning gör detta till ett mångsidigt alternativ.`}</p>
    <h3 {...{
      "id": "haibike-trekking-6-series"
    }}>{`Haibike Trekking 6 Series`}</h3>
    <p><strong parentName="p">{`Nyckelfunktioner:`}</strong></p>
    <ul>
      <li parentName="ul">{`Kraftfull Yamaha mittmotor med PW-ST System`}</li>
      <li parentName="ul">{`Batterikapacitet på 630 Wh`}</li>
      <li parentName="ul">{`Räckvidd upp till 100 km`}</li>
      <li parentName="ul">{`Shimano Deore 10-växlars system`}</li>
      <li parentName="ul">{`Robust ram och Schwalbe däck`}</li>
    </ul>
    <p>{`Haibike Trekking 6 är synonymt med högsta prestanda och komfort. Med en lång räckvidd och avancerade funktioner är denna modell idealisk för både långpendling och äventyr på varierande terräng.`}</p>
    <h2 {...{
      "id": "köpguide-så-väljer-du-rätt-haibike-elcykel"
    }}>{`Köpguide: Så Väljer du Rätt Haibike Elcykel`}</h2>
    <h3 {...{
      "id": "1-tänk-på-användningsområde"
    }}>{`1. Tänk på användningsområde`}</h3>
    <p>{`Planerar du att mestadels använda cykeln för pendling i stadsmiljö, eller vill du kunna utforska tuffare terräng? Serier som Haibike Trekking 4 och 5 är utmärkta val för allround-användning, medan Trekking 6 passar för längre turer och mer krävande utflykter.`}</p>
    <h3 {...{
      "id": "2-batterikapacitet-och-räckvidd"
    }}>{`2. Batterikapacitet och räckvidd`}</h3>
    <p>{`Om du har långa dagliga pendlingssträckor eller älskar att ta långa turer under helgerna, är en modell med hög batterikapacitet och lång räckvidd som Trekking 6 rätt val för dig. För något kortare distanser kan Trekking 4 och 5 serierna vara tillräckliga.`}</p>
    <h3 {...{
      "id": "3-komfort-och-dämpning"
    }}>{`3. Komfort och dämpning`}</h3>
    <p>{`Komfort är viktigt för en konsekvent trevlig cykelupplevelse. Haibike elcyklar är utrustade med olika typer av dämpningssystem och däck. Trekking 4-serien erbjuder en smidig och stabil färd, medan Trekking 6-serien erbjuder ännu högre komfort för tuffare terräng.`}</p>
    <h2 {...{
      "id": "sammanfattning"
    }}>{`Sammanfattning`}</h2>
    <p>{`Med sina högkvalitativa komponenter, smarta teknologier och eleganta design är Haibike elcyklar det självklara valet för den som söker en pålitlig och mångsidig elcykel. Oavsett om du väljer Haibike Trekking 4, 5 eller 6, kan du vara säker på att investera i en cykel som kombinerar prestanda, komfort och hållbarhet.`}</p>
    <p>{`Utforska våra Haibike elcyklar idag och ta steget mot en mer hållbar och äventyrlig cykelupplevelse.`}</p>
    <ProductsDisplay data={props.data.allMongodbStoreProducts} location={props.location} mdxType="ProductsDisplay"></ProductsDisplay>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      